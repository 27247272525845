<template>
  <div>

    <b-card v-if='isDataLoaded'>
      <div class='text-center text-success my-2'>
        <b-spinner class='align-middle' />
      </div>
    </b-card>

    <b-card v-if='!hasPermissions("view dashboard")'>
      <div class='text-center text-success my-2'>
        <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='currentColor'
             class='bi bi-lock-fill lock-tab' viewBox='0 0 16 16'>
          <path
            d='M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2' />
        </svg>
      </div>
    </b-card>

    <div class='row' v-else>

      <div class='col-12 col-md-3'>
        <div
          @click="goRouteCustom('real-estate-licensed')"
          class='card cursor-pointer'
        >
          <div class='card-body'>
            <h5 class='card-title'>{{ $t('authorized_real_estates') }}</h5>
            <h4 class='card-text'>{{ statistics.authorized_real_estates }}</h4>
          </div>
        </div>
      </div>
      <div class='col-12 col-md-3'>
        <div
          @click="goRouteCustom('real-estate-not-licensed')"
          class='card cursor-pointer'>
          <div class='card-body'>
            <h5 class='card-title'>{{ $t('not_authorized_real_estates') }}</h5>
            <h4 class='card-text'>{{ statistics.not_authorized_real_estates }}</h4>
          </div>
        </div>
      </div>

      <div class='col-12 col-md-3'>
        <div
          @click="goRouteCustom('customers')"
          class='card cursor-pointer'>
          <div class='card-body'>
            <h5 class='card-title'>{{ $t('customers_count') }}</h5>
            <h4 class='card-text'>{{ statistics.customers_count }}</h4>
          </div>
        </div>
      </div>

      <div class='col-12 col-md-3'>
        <div
          @click="goRouteCustom('real-estate-requests')"
          class='card cursor-pointer'>
          <div class='card-body'>
            <h5 class='card-title'>{{ $t('real_estate_requests_count') }}</h5>
            <h4 class='card-text'>{{ statistics.real_estate_requests_count }}</h4>
          </div>
        </div>
      </div>

      <div class='col-12 col-md-3'>
        <div
          @click="goRouteCustom('real-estate-leave-on-us')"
          class='card cursor-pointer'>
          <div class='card-body'>
            <h5 class='card-title'>{{ $t('real_estate_leave_it_on_us_count') }}</h5>
            <h4 class='card-text'>{{ statistics.real_estate_leave_it_on_us_count }}</h4>
          </div>
        </div>
      </div>

      <div class='col-12 col-md-3'>
        <div
          @click="goRouteCustom('marketing-request')"
          class='card cursor-pointer'>
          <div class='card-body'>
            <h5 class='card-title'>{{ $t('real_estate_marketing_count') }}</h5>
            <h4 class='card-text'>{{ statistics.real_estate_marketing_count }}</h4>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import { getPermissions } from '@core/utils/utils'

export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['errors', 'name'],
  created() {
    this.getData()
    this.permissions = getPermissions()

    this.user = JSON.parse(localStorage.getItem('userData'))
  },
  data() {
    return {
      statistics: {},
      isDataLoaded: false,
      user: null,
      permissions: [],
    }
  },

  methods: {
    hasPermissions(key) {
      if (this.permissions && this.permissions['dashboard']) {
        return this.permissions['dashboard']['can_view'] ?? false
      }

      return false
    },
    goRouteCustom(name) {

      this.$router.push({
        name: name,
      })
    },

    getData() {
      this.isDataLoaded = true
      this.axios.get(`/get-statistics`)
        .then(res => {
          this.isDataLoaded = false
          this.statistics = res.data
        })
    },
  },

}
</script>

<!--<template>-->
<!--  <div class='map-app'>-->

<!--    <div id="map-layout">-->
<!--      <Map/>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<style>-->
<!--.map-app {-->
<!--  display: flex;-->
<!--  height: 50vh;-->
<!--  padding: 0;-->
<!--  flex: 1;-->
<!--}-->
<!--#map-layout {-->
<!--  flex: 1;-->
<!--  display: flex;-->
<!--  position: relative;-->
<!--}-->
<!--</style>-->
